import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppVersionPropData } from "./interface";
import { Link, StyledTd } from "./styles" 
import axios from "axios";

async function getUname(packageName: string): Promise<string | null> {
  try {
      const response = await axios.get(`https://ws75.aptoide.com/api/7/app/get/package_name=${packageName}`);
      const data = response.data;
      
      if (data?.nodes?.meta?.data?.uname) {
          return data.nodes.meta.data.uname;
      } else {
          console.error('Error: Unable to fetch uname from response data');
          return null;
      }
  } catch (error) {
      console.error('Error occurred while fetching data:', error);
      return null;
  }
}
export function AppVersion(props: AppVersionPropData) {
  const [link, setLink] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (props.text && props.store && props.packageName) {
        switch (props.store) {
          case "aptoide":
              try {
                const uname = await getUname(props.packageName);
                if (uname) {
                  const aptoideLink = `https://${uname}.en.aptoide.com`;
                  setLink(aptoideLink);
                } else {
                  const searchLink = `https://en.aptoide.com/search?query=${props.packageName}`;
                  setLink(searchLink);
                }
              } catch (error) {
                console.error('Error:', error);
              }
            break;
          case "googleplay":
            const playStoreLink = `https://play.google.com/store/apps/details?id=${props.packageName}`;
            setLink(playStoreLink);
            break;
          case "apkpure":
            const apkpureLink = `https://apkpure.com/gmail/${props.packageName}`;
            setLink(apkpureLink);
            break;
          case "cafebazaar":
            const cafebazaarLink = `https://cafebazaar.ir/app/${props.packageName}`;
            setLink(cafebazaarLink);
            break;
          case "meizu":
            const meizuLink = `https://app.meizu.com/apps/public/detail?package_name=${props.packageName}`;
            setLink(meizuLink);
            break;
          default:
            setLink(null);
        }
      }
      else {
        setLink(null);
      }
    })();
  }, [props.packageName, props.store, props.text]);

  return (
    <StyledTd>
      {link ? (
        <Link target="_blank" href={link} rel="noreferrer">{props.text}</Link>
      ) : (
        props.text ? (
          props.text
        ) : (
          "N/A"
        )
      )}
    </StyledTd>
  );
  
}

AppVersion.propTypes = {
  text: PropTypes.string,
  link: PropTypes.string,
  packageName: PropTypes.string,
};


